// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 786px) {
    .chart-container canvas {
        height: 250px !important;
        width: 380px !important;
        padding: 0;
    }
    
}


@media screen and (min-width: 786px) and (max-width: 986px) {
    .chart-container canvas {
        width: 510px;
        height: 510px;
        padding: 0;
    }

}

@media screen and (min-width: 986px) {
    .chart-container canvas {
        min-width: 90%;
        min-height: 280px;
        max-width: 90%;
        max-height: 280px;
    }
    
}
`, "",{"version":3,"sources":["webpack://./src/components/expchart/style.css"],"names":[],"mappings":"AAAA;IACI;QACI,wBAAwB;QACxB,uBAAuB;QACvB,UAAU;IACd;;AAEJ;;;AAGA;IACI;QACI,YAAY;QACZ,aAAa;QACb,UAAU;IACd;;AAEJ;;AAEA;IACI;QACI,cAAc;QACd,iBAAiB;QACjB,cAAc;QACd,iBAAiB;IACrB;;AAEJ","sourcesContent":["@media screen and (max-width: 786px) {\n    .chart-container canvas {\n        height: 250px !important;\n        width: 380px !important;\n        padding: 0;\n    }\n    \n}\n\n\n@media screen and (min-width: 786px) and (max-width: 986px) {\n    .chart-container canvas {\n        width: 510px;\n        height: 510px;\n        padding: 0;\n    }\n\n}\n\n@media screen and (min-width: 986px) {\n    .chart-container canvas {\n        min-width: 90%;\n        min-height: 280px;\n        max-width: 90%;\n        max-height: 280px;\n    }\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

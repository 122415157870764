// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 786px) {

    .dev-chart {
        max-width: 310px;
        max-height: 310px;
    }
    
    .dev-chart canvas {
        width: 350px;
        height: 370px;
        padding: 0;
    }
    
}


@media screen and (min-width: 786px) and (max-width: 986px) {
    .dev-chart {
        min-width: 510px;
        min-height: 510px;
    }

    .dev-chart canvas {
        width: 510px;
        height: 510px;
        padding: 0;
    }
}

@media screen and (min-width: 986px) {
    .dev-chart {
        width: 100%;
        min-width: 350px;
        min-height: 350px;
        max-width: 350px;
        max-height: 350px;
        padding: 1.5rem 0rem;
    }

    .dev-chart canvas {
        min-width: 100%;
        min-height: 300px;
        max-width: 100%;
        max-height: 300px;
    }
    
}
`, "",{"version":3,"sources":["webpack://./src/components/devchart/style.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;QACI,YAAY;QACZ,aAAa;QACb,UAAU;IACd;;AAEJ;;;AAGA;IACI;QACI,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;QACI,YAAY;QACZ,aAAa;QACb,UAAU;IACd;AACJ;;AAEA;IACI;QACI,WAAW;QACX,gBAAgB;QAChB,iBAAiB;QACjB,gBAAgB;QAChB,iBAAiB;QACjB,oBAAoB;IACxB;;IAEA;QACI,eAAe;QACf,iBAAiB;QACjB,eAAe;QACf,iBAAiB;IACrB;;AAEJ","sourcesContent":["@media screen and (max-width: 786px) {\n\n    .dev-chart {\n        max-width: 310px;\n        max-height: 310px;\n    }\n    \n    .dev-chart canvas {\n        width: 350px;\n        height: 370px;\n        padding: 0;\n    }\n    \n}\n\n\n@media screen and (min-width: 786px) and (max-width: 986px) {\n    .dev-chart {\n        min-width: 510px;\n        min-height: 510px;\n    }\n\n    .dev-chart canvas {\n        width: 510px;\n        height: 510px;\n        padding: 0;\n    }\n}\n\n@media screen and (min-width: 986px) {\n    .dev-chart {\n        width: 100%;\n        min-width: 350px;\n        min-height: 350px;\n        max-width: 350px;\n        max-height: 350px;\n        padding: 1.5rem 0rem;\n    }\n\n    .dev-chart canvas {\n        min-width: 100%;\n        min-height: 300px;\n        max-width: 100%;\n        max-height: 300px;\n    }\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react"; 
import ams from "../../assets/images/ams.jpg";
import FadeIn from "../../components/animation";
import "./style.css";
import ExpChart from "../../components/expchart";
import DevChart from "../../components/devchart";

const ReadMore = ({ children, maxChars=800 }) => { 
  const [isReadMore, setIsReadMore] = useState(true); 
  const toggleReadMore = () => { 
    setIsReadMore(!isReadMore); 
  };
  let text = isReadMore ? children.substring(0, maxChars) :children
  if (children.length <= 700) {
    return <div className="about">
              <div className="about-txt">
                  <FadeIn isVisible={1} children={10}>
                  </FadeIn>
              </div>
              <div className="about-img">
                  <img src={ams}  alt=""/>               
              </div>
           </div>
    }
  return<div className="about-us">
          <div className="about">
            <div className="about-txt">
                <FadeIn isVisible={1} children={10}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My name is <strong style={{'fontSize': '25px', 'fontFamily': 'sans-serif'}} >Kyaw Ye Htut</strong> and I’m a<span style={{'fontSize': '22px', 'fontFamily': 'sans-serif'}}>  Network Engineer.</span> { text}
                <span onClick={toggleReadMore} className="read-or-hide"> 
                {isReadMore? '....Read More': 'Read Less'}
                </span>
                </FadeIn>
            </div>
            <div className="about-img">
                <img src={ams}  alt=""/>               
            </div>
          </div>
          <div className="chart">
              <div className="devchart"><DevChart /></div><div className="expchart"><ExpChart /></div>
          </div>
        </div> 
}

function About() {
    return(
      <div> 
          <ReadMore> 
          Experienced Senior Network Administrator at Sun Community Health, providing reliable and secure network and system support for a leading organization that delivers quality health care services to underserved communities in Myanmar. With over 9 years of experience in network engineering and administration, I hold certifications in Cisco and Fortinet technologies.

I contribute significantly to the development and updating of business continuity and disaster recovery plans, as well as the maintenance of the local area network and internet working equipment. Proficient in administering MS Window Server, Active Directory, firewalls, endpoint protection, and Microsoft 365 and power platform. Skilled in Linux administration, infrastructure, routing protocols, and switching, with experience in a top Microfinance Institution in Myanmar.

I excel in computer hardware troubleshooting, root cause analysis, and fostering strong interpersonal relationships. Passionate about leveraging technical proficiency to enhance health care delivery and improve the lives of undeserved communities.
        </ReadMore> 
      </div> 
    )    
}

export default About;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 2000ms, transform 2000ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 2000ms, transform 2000ms;
}

.PhoneInputInput:focus{
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,wBAAwB;IACxB,4CAA4C;AAChD;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,qBAAqB;IACrB,4CAA4C;AAChD;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".alert-enter {\n    opacity: 0;\n    transform: scale(0.9);\n}\n\n.alert-enter-active {\n    opacity: 1;\n    transform: translateY(0);\n    transition: opacity 2000ms, transform 2000ms;\n}\n\n.alert-exit {\n    opacity: 1;\n}\n\n.alert-exit-active {\n    opacity: 0;\n    transform: scale(0.9);\n    transition: opacity 2000ms, transform 2000ms;\n}\n\n.PhoneInputInput:focus{\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
